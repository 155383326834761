
.ServiceCenterWarpMain {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
}

.ServiceGameCenterWarp {
    width: 1400px;
    min-height: 611px;
    margin: 0 auto;
    display: flex;
    height: 711px;
    gap: 35px;
}

.notice_contents_box {
    position: relative;
    display: block;
    width: 998px;
    height: auto;
    background-color: #ffffff;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-top: 2px solid #4e4e4e;
    margin-top: 40px;
}

.notice_contents {
    display: block;
    width: 100%;
    height: 68px;
    border-bottom: 1px solid #dbdbdb;
}

.notice_contents_inner {
    display: block;
    width: 938px;
    height: 25px;
    font-size: 16px;
    color: #4e4e4e;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 20px;
}

.notice_contents_title {
    width: 660px;
    height: 100%;
}

.notice_contents_admin {
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 50px;
    margin: -25px 0 0 727px;
}

.notice_contents_date {
    position: absolute;
    display: inline-block;
    width: 100px;
    height: 50px;
    margin: -25px 0 0 840px;
    text-align: right;
}

.notice_paging_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 998px;
    height: 48px;
    border: 1px solid #dbdbdb;
    text-align: center;
    margin-top: 40px;
    background-color: #ffffff;
}

.notice_paging_box_hover:hover{
    color: #15a3e1;
}

.paging_number_button {
    text-align: center;
    width: 40px;
    height: 46px;
    font-size: 16px;
    background-color: #15a3e1;
    color: #ffffff;
    border-bottom: 2px solid #15a3e1;
}

.service_paging_button {
    display: flex;
}

.service_paging_button div {
    /*border: 1px solid #dbdbdb;*/
    cursor: pointer;
}

.service_content_paging_button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.service_rigth_paging {
    padding-right: 15px;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
}

.notice_search_box {
    position: absolute;
    display: inline-block;
    width: 280px;
    height: 35px;
    background-color: #f3f3f3;
    margin: 18px 0 0 407px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.notice_input_box {
    position: absolute;
    display: inline-block;
    width: 256px;
    height: 37px;
    font-size: 14px;
    padding: 8px 0 0 12px;
    z-index: 1;
}

.notice_input_box input {
    color: #4e4e4e;
    width: 100%;
}

.notice_search_button {
    position: absolute;
    display: inline-block;
    width: 52px;
    height: 35px;
    color: #ffffff;
    background-color: #4e4e4e;
    z-index: 1;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: 281px;
}

.category_title_box {
    position: relative;
    display: block;
    width: 100%;
    height: 78px;
    line-height: 78px;
    font-size: 20px;
    font-weight: bold;
    text-align: left
}

.category_box_sub_title {
    height: auto;
    min-height: 147px;
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    background-color: #f9f9f9;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    margin: 0 auto;
}

.category_box_sub_title_2 {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    font-weight: normal;
    color: #7b7b7b;
    margin: 10px 64px 0 14px;
    line-height: 23px;
}


.service_category_title {
    padding-left: 30px;
}

.service-center-top-my-section-tit {
    position: relative;
    display: inline-block;
    width: auto;
    height: 30px;
    font-size: 19px;
    font-weight: normal;
    color: #7b7b7b;
    cursor: pointer;
    padding: 12px 0 0 0;
    /* margin: 0 30px 0 30px; */
    z-index: 2;
}

.service_tab_links {
    height: 40px;
    border: none;
    outline: none;
    font-size: 20px;
    text-align: left;
    color: #7b7b7b;
}

.service_tab_links.active {
    color: #15a3e1;
}

.service_tab_links.active .service-top-my-section-tit{
    border-bottom: 3px solid #15a3e1;
}

.service-top-my-section-tit {
    position: relative;
    display: inline-block;
    width: auto;
    height: 30px;
    font-size: 19px;
    font-weight: normal;
    cursor: pointer;
    padding: 12px 0 0 0;
    margin: 0 30px 0 30px;
    z-index: 2;
}

.man_to_man_category_hover:hover .man_to_man_category{
    display: flex;
}

.man_category_color {
    color: #15a3e1;
}


.man_to_man_category {
    width: 260px;
    height: 30px;
    position: absolute;
    font-size: 14px;
    margin-left: 28px;
    line-height: 2.5;
    cursor: pointer;
    display: none;
    gap: 15px;
    color: black;
}

.man_to_man_category span:hover {
    color: #15a3e1;
}

.inquiry_sub_page {
    color: #15a3e1;
}

.qa_box {
    width: 810px;
    height: 37px;
    padding: 60px 0 60px 0;
    margin: 0 auto;
}

.qa_title {
    position: relative;
    display: inline-block;
    color: #4e4e4e;
    font-size: 34px;
    font-weight: bold;
    margin: -8px 0 0 0;
}

.qa_search_box {
    position: absolute;
    display: inline-block;
    width: 566px;
    height: 37px;
    font-size: 14px;
    color: #ffffff;
    background-color: #4e4e4e;
    margin: 0 0 0 10px;
}

.search_box_drop_box {
    display: inline-block;
    width: 140px;
    height: 100%;
    background-color: #4e4e4e;
}

.drop_box_select {
    display: inline-block;
    position: relative;
    width: 140px;
    height: 100%;
    font-size: 14px;
    color: #ffffff;
    border: none;
    z-index: 1;
    background: #4e4e4e url(../images/servicecenter_img/Icon-dropbox.png) no-repeat 95% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 14px;
}

.drop_box_select option {
    background: #4e4e4e
}

.qa_input_box {
    position: absolute;
    display: inline-block;
    width: 400px;
    height: 37px;
    font-size: 14px;
    padding: 10px 0 0 10px;
}

.qa_input_box input {
    color: #fff;
    width: 100%;
}

.qa_search_img {
    position: relative;
    margin: -45px 0 0 745px;
}

.qa_search_img_src{
    width: 51px;
    height: 43px;
    background: url("../images/servicecenter_img/Icon-Q.png") center;
}

.qa_search_img_src:hover {
    cursor: pointer;
    width: 51px;
    height: 43px;
    background: url("../images/servicecenter_img/Icon-Q2.png") center;
}

.service_center_main_category_box {
    position: relative;
    display: block;
    width: 995px;
    height: 275px;
    background-color: #ffffff;
    cursor: default;
    border: 1px solid #dbdbdb;
    border-top: 2px solid #4e4e4e;
    margin-bottom: 40px;
}

.service_category_box_title {
    position: relative;
    display: block;
    width: 100%;
    height: 78px;
    text-align: center;
    line-height: 78px;
    font-size: 20px;
    font-weight: bold;
}

.service_category_box_subtitle {
    position: relative;
    display: block;
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: #f9f9f9;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    margin: 0 auto;
}

.service_subtitle_text_box {
    position: relative;
    display: inline-block;
    width: 160px;
    height: auto;
    font-weight: normal;
    color: #7b7b7b;
    margin: 10px 64px 0 14px;
    line-height: 23px;
}

.service_subtitle_text_box_2 {
    cursor: unset !important;
    font-weight: bold;
    color: #4e4e4e;
}

.service_sub_title_text {
    cursor: pointer;
}

.service_sub_title_text:hover {
    color: #15a3e1;
}


.qa_section {
    position: relative;
    height: auto;
    min-height: 386px;
    border: 1px solid #e2e2e2;
    border-bottom: none;
    background-color: #fff;
}

.service_tab_box {
    width: 100%;
    height: 63px;
    border-bottom: 1px solid #d3d3d3;
}

.service_tab_a {
    position: absolute;
    width: 933px;
    height: 45px;
    text-align: left;
    margin: 19px 0 0 29px;
}

.service_tab_links_a {
    margin-left: 150px;
    width: auto;
    height: 44px;
    border: none;
    outline: none;
    font-size: 16px;
    color: #959595;
}

.service_tab_links_a.active {
    font-size: 16px;
    border-bottom: 2px solid #4e4e4e;
    color: #4e4e4e;
    border-bottom-width: 20%;
}

.qa_contents_box {
    display: block;
    width: 933px;
    height: auto;
    min-height: 210px;
    margin: 30px 0 0 30px;
    border-top: 2px solid #4e4e4e;
}

.qa_contents {
    display: block;
    width: 100%;
    height: 72px;
    font-size: 16px;
    color: #4e4e4e;
    border-bottom: 1px solid #dbdbdb;
}

.qa_contents.active {
    background-color: #57bce8;
    color: #ffffff;
}

.qa_contents_inner_text {
    display: inline-block;
    height: 50px;
    padding-top: 23px;
}

.qa_contents:hover {
  background-color: #57bce8;
  color: #ffffff;
}

.qa_contents button:hover {
  color: #ffffff;
}

.qa_notice_paging_box {
    display: flex;
    justify-content: center;
    width: 998px;
    height: 48px;
    border: 1px solid #dbdbdb;
    text-align: center;
    margin-top: 40px;
    background-color: #ffffff;
}

.qa_pagination_buttons {
    width: 40px;
    height: 46px;
    font-size: 16px;
    background-color: #15a3e1;
    color: #ffffff;
    border-bottom: 2px solid #15a3e1;
}

.qa_pagination_buttons_2 {
    width: 40px;
    height: 46px;
    font-size: 16px;
    /*color: #4e4e4e;*/
}

.qa_pagination_buttons_2:hover {
    color: #15a3e1;
    border-bottom: 2px solid #15a3e1;
}


.qa_pagination_buttons:hover {
    background-color: #15a3e1;
    color: #ffffff;
}

.quit_box {
    width: 998px;
    min-height: 300px;
    height: auto;
    border: 1px solid #dbdbdb;
    border-top: 2px solid #4e4e4e;
    background-color: #f9f9f9;
    margin-top: 40px;
}

.quit_title_box {
    position: relative;
    display: block;
    width: 100%;
    height: 87px;
    font-size: 20px;
    font-weight: bold;
    background-color: #ffffff;
    border-bottom: 1px solid #efefef;
}

.quit_ok_no_button {
    position: absolute;
    display: inline-block;
    width: 30px;
    height: 25px;
    font-size: 14px;
    color: #ffffff;
    background-color: #15a3e1;
    text-align: center;
    padding-bottom: 2px;
    margin-top: 30px;
    margin-left: 30px;
    border-radius: 5px;
}

.quit_title {
    width: 900px;
    height: 56px;
    padding: 28px 0 0 70px;
}

.quit_contents {
    display: block;
    width: 900px;
    height: auto;
    font-weight: normal;
    font-size: 16px;
    color: #4e4e4e;
    padding: 32px 0 32px 70px;
}

.inquiry_center_warp_box {
    display: inline-block;
    width: 498px;
    height: 210px;
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    margin: 0 -2px 0 0;
}

.inquiry_contents_box_1 {
    width: auto;
    height: 150px;
    padding: 23px 0 0 30px;
}

.inquiry_title_box {
    font-size: 20px;
    font-weight: bold;
    color: #4e4e4e;
    padding-bottom: 19px;
}

.inquiry_title_box_2 {
    font-size: 14px;
    color: #7b7b7b;
    line-height: 1.7;
    padding-bottom: 15px;
}

.inquiry_button_box {
    position: absolute;
    font-size: 16px;
    color: #ffffff;
    background-color: #15a3e1;
    padding: 9px 86px 9px 14px;
    margin-top: 6px;
    border-radius: 5px;
}

.inquiry_button_img {
    position: absolute;
    background-repeat: no-repeat;
    margin: -18px 0 0 122px;
}

.inquiry_box_img {
    position: absolute;
    display: inline-block;
    margin: -55px 0 0 261px;
}


.man_contents_box {
    position: relative;
    display: block;
    width: 998px;
    height: auto;
    background-color: #ffffff;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-top: 2px solid #4e4e4e;
}

.man_contents {
    display: block;
    width: 100%;
    height: 76px;
    font-size: 16px;
    border-bottom: 1px solid #dbdbdb;
    line-height: 74px;
}

.man_contents_inner_left_box {
    position: relative;
    display: inline-block;
    width: 335px;
    height: 100%;
    font-weight: bold;
    background-color: #f9f9f9;
    border-right: 1px solid #efefef;
    text-align: center;
}

.man_contents_inner_right_box {
    display: inline-block;
    height: 50%;
    width: 60%;
    padding-left: 26px;
}

.man_contents_inner_right_box option{
    background: #f3f3f3;
}

.man_drop_box_select {
    display: inline-block;
    position: relative;
    height: 100%;
    font-size: 14px;
    border: none;
    z-index: 1;
    background: #f3f3f3 url('../images/servicecenter_img/Icon-dropbox2.png')no-repeat 95% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 14px;
    width: 280px;
    color: #000000;
}

.man_password_box {
    position: absolute;
    display: inline-block;
    width: 602px;
    height: 38px;
    background-color: #f3f3f3;
    margin: 19px 0 0 26px;
}

.man_password_input_box {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 14px;
    padding: 8px 0 0 12px;
    z-index: 1;
}

.man_txt_div {
    position: absolute;
    display: inline-block;
    width: 602px;
    height: 212px;
    background-color: #f3f3f3;
    margin: 19px 0 0 26px;
}

.man_text_area {
    width: 94%;
    height: 95%;
    border: none;
    resize: none;
    padding: 5px 14px;
    background-color: #f3f3f3;
}

.ok_no_box_button {
    display: block;
    width: 100%;
    height: 42px;
    padding-top: 40px;
    text-align: center;
}

.ok_no_button {
    position: relative;
    display: inline-block;
    font-size: 16px;
    color: #ffffff;
    background-color: #15a3e1;
    padding: 9px 46px 9px 14px;
    margin-top: 6px;
    border-radius: 5px;
}

.man_ok_no_button_img {
    position: absolute;
    margin: -18px 0 0 53px;
}

.billionaire_contents_box {
    width: 100%;
    height: 75px;
    background-color: #f9f9f9;
    border: 1px solid #dbdbdb;
    border-top: 2px solid #4e4e4e;
    margin-top: 30px;
    text-align: center;
}

.billionaire_contents_box_2 {
    width: 100%;
    height: 75px;
    color: #7b7b7b;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    border-top: none;
    text-align: center;
    line-height: 70px;
    cursor: pointer;
}

.billionaire_contents_title {
    display: inline-block;
    height: 100%;
    color: #4e4e4e;
    font-size: 16px;
    line-height: 4.7;
}

.email_contents_box {
    width: 100%;
    min-height: 155px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    border-top: 2px solid #4e4e4e;
}

.email_contents_date_box {
    position: absolute;
    width: 100px;
    height: auto;
    font-size: 16px;
    margin: 27px 0 0 888px;
}

.email_contents_title_box {
    width: 940px;
    height: 81px;
    font-size: 16px;
    border-bottom: 1px solid #dbdbdb;
    margin: 0 0 0 30px;
}

.email_q_text_box {
    width: 910px;
    height: auto;
    font-size: 16px;
    margin: 27px 0 27px 68px;
    word-wrap: break-word;
}

.email_gray_box {
    width: 100%;
    min-height: 162px;
    height: auto;
    background-color: #f9f9f9;
    border-top: 1px solid #dbdbdb;
}

.policy_center_warp {
    display: flex;
    justify-content: center;
    padding: 40px 0 80px 0;
    background-color: #f5f5f5;
}

.search_center_warp_box3 {
    display: inline-block;
    width: 331px;
    height: 175px;
    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    margin: 0 -2.6px 0 0;
}

.search_box3_contents_box {
    width: 300px;
    height: 120px;
    padding: 23px 0 0 30px;
}

.search_box3_title {
    font-size: 20px;
    font-weight: bold;
    color: #4e4e4e;
    padding-bottom: 19;
}

.search_box3_text {
    font-size: 14px;
    color: #7b7b7b;
    line-height: 1.7;
    padding-bottom: 15px;
    cursor: pointer;
}

.search_box3_img {
    position: absolute;
    display: inline-block;
    margin: -77px 0 0 142px;
}

.user_restriction_main_title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 998px;
    font-size: 20px;
    font-weight: 600;
    padding-top: 30px;
}

.user_restriction_sub_title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-size: 18px;
}

.user_restriction_sub_title_2 {
    font-size: 16px;
    margin-top: 20px;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_restriction_buttons_div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 101px;
    margin-top: 35px;
}

.user_restriction_round_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px
}

.user_restriction_round_buttons input{
    appearance: revert;
    width: 25px;
    height: 25px;
    cursor: pointer;
}


.buttons_not_filled {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  border: 2px #0f69b4 solid;
  cursor: pointer;
}

.buttons_filled {
  width: 20px;
  height: 20px;
  background: #0f69b4;
  border-radius: 999px;
  border: 2px #0f69b4 solid;
  cursor: pointer;
}

.user_restriction_popup {
    display: block;
    position: fixed;
    z-index: 2000;
    padding-top: 9.1%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}

.user_restriction_popup-content {
    display: block;
    position: relative;
    z-index: 3000;
    justify-content: center;
    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888;
    width: 700px;
    height: 350px;
    border-radius: 10px;
}

.user_restrict_popup_div_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-image: url(../images/servicecenter_img/servicecenter_P01.jpg);
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
}

.user_restrict_popup_div_2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-top: 35px;
}

.user_restrict_popup_div_3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-top: 20px;
}

.user_restrict_popup_div_4 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    margin-top: 25px;
}

.UserRestrictPopupDiv2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-top: 35px;
}