.game_service_center_warp {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
}

.game_service_game_center {
    width: 1400px;
    display: flex;
    justify-content: left;
    gap: 35px;
    margin-left: 150px;
}


.game_center_warp_inner {
    width: 1000px;
    margin: 0;
    padding-top: 40px;
}

.game_policy_center_warp {
    display: flex;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    border: 2px solid rgb(78, 78, 78);
}

.game_search_center_warp_box3 {
    display: inline-block;
    width: 331px;
    height: 175px;
    background-color: #333;
    color: #FFFFFF;
    border: 2px solid rgb(78, 78, 78);
    margin: 0 -2.6px 0 0;
}

.game_man_contents_inner_left_box {
    position: relative;
    display: inline-block;
    width: 335px;
    height: 100%;
    font-weight: bold;
    background-color: #333;
    border-right: 2px solid rgb(78, 78, 78);
    text-align: center;
}

.game_man_password_box {
    position: absolute;
    display: inline-block;
    width: 602px;
    height: 38px;
    background-color: black;
    color: #FFFFFF;
    margin: 19px 0 0 26px;
    /*border: 2px solid rgb(78, 78, 78);*/
}

.game_man_drop_box_select {
    display: inline-block;
    position: relative;
    height: 100%;
    font-size: 14px;
    /*border: none;*/
    z-index: 1;
    background: black url(../images/ingame_select_icon.png) no-repeat 95% 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 14px;
    width: 280px;
    color: white;
}

.game_man_text_area {
    width: 94%;
    height: 95%;
    border: none;
    resize: none;
    padding: 5px 14px;
    background-color: black;
    color: #FFFFFF;
}

.game_man_contents {
    display: block;
    width: 100%;
    height: 76px;
    font-size: 16px;
    border-bottom: 2px solid rgb(78, 78, 78);
    line-height: 74px;
}

.game_man_contents_box {
    position: relative;
    display: block;
    width: 998px;
    height: auto;
    background-color: #ffffff;
    border-left: 2px solid rgb(78, 78, 78);
    border-right: 2px solid rgb(78, 78, 78);
    border-top: 2px solid rgb(78, 78, 78);
}

.game_sub_main_title_box {
    display: block;
    width: 100%;
    height: 70px;
    background-color: #333;
    border: 2px solid rgb(78, 78, 78);
    margin-bottom: 40px;
    color: #FFFFFF;
}

.game_billionaire_contents_box {
    width: 100%;
    height: 75px;
    background-color: #333;
    border: 2px solid #4e4e4e;
    margin-top: 30px;
    text-align: center;
    color: #FFFFFF;
}

.game_billionaire_contents_box_2 {
    width: 100%;
    height: 75px;
    color: #7b7b7b;
    font-size: 14px;
    background-color: #333;
    border: 2px solid rgb(78, 78, 78);
    border-top: none;
    text-align: center;
    line-height: 70px;
    cursor: pointer;
}

.game_notice_paging_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 998px;
    height: 48px;
    background-color: #333;
    border: 2px solid rgb(78, 78, 78);
    text-align: center;
    margin-top: 40px;
}

.game_service_right_paging {
    padding-right: 15px;
    border-right: 2px solid rgb(78, 78, 78);
    border-left: 2px solid rgb(78, 78, 78);
}


.game_billionaire_contents_title {
    display: inline-block;
    height: 100%;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 4.7;
}


.game_email_contents_box {
    width: 100%;
    min-height: 155px;
    height: auto;
    background-color: #333;
    border: 2px solid rgb(78, 78, 78);
}

.game_email_contents_title_box {
    width: 940px;
    height: 81px;
    font-size: 16px;
    border-bottom: 2px solid rgb(78, 78, 78);
    margin: 0 0 0 30px;
}

.game_email_gray_box {
    width: 100%;
    min-height: 162px;
    height: auto;
    border-top: 2px solid rgb(78, 78, 78);
}

.red_dot {
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
    margin-left: 238px;
    margin-top: -28px;
}

.game_sub_main_title {
    position: relative;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0 0 27px;
    cursor: pointer;
}

.game_sub_main_title.active {
    color: rgb(255, 232, 0);
}


.game_center_warp_inner_content {
    width: 1000px;
    height: auto;
    margin: 0;
    padding-bottom: 100px;
}

.game_user_restriction_popup_content {
    display: block;
    position: relative;
    z-index: 3000;
    justify-content: center;
    background-color: #333;
    margin: auto;
    border: 2px solid rgb(78, 78, 78);
    width: 700px;
    height: 350px;
    border-radius: 10px;
    color: white;
}


.game_service_center_popup {
    display: block;
    position: fixed;
    z-index: 2000;
    padding-top: 23%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}


.game_notice_popup_content {
    display: block;
    position: relative;
    z-index: 3000;
    justify-content: center;
    background-color: #333;
    margin: auto;
    border: 2px solid rgb(78, 78, 78);
    width: 300px;
    height: 150px;
    border-radius: 10px;
    color: white;
}

.game_user_restrict_popup_div_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
    color: white;
}

.game_contents_inner_right_box {
    display: inline-block;
    height: 50%;
    width: 60%;
    padding-left: 26px;
}

.game_contents_inner_right_box option{
    background: #333;
}

.game_restriction_round_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 101px;
}

.game_restriction_round_buttons input{
    -webkit-appearance: radio;
    appearance: revert;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
}


.game_notice_contents_box {
    position: relative;
    display: block;
    width: 998px;
    height: auto;
    background-color: #333;
    border: 2px solid rgb(78, 78, 78);
    margin-top: 40px;
    color: #FFFFFF;
}

.game_notice_contents {
    display: block;
    width: 100%;
    height: 68px;
    border: 2px solid rgb(78, 78, 78);
}

.game_category_box_sub_title {
    height: auto;
    min-height: 147px;
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    background-color: #333;
    border-top: 2px solid rgb(78, 78, 78);
    border-bottom: 2px solid rgb(78, 78, 78);
    margin: 0 auto;
    color: #FFFFFF;
}

.game_qa_section {
    position: relative;
    height: auto;
    min-height: 386px;
    border: 2px solid rgb(78, 78, 78);
    border-bottom: none;
    background-color: #333;
}

.game_service_tab_box {
    width: 100%;
    height: 63px;
    border-bottom: 1px solid rgb(78, 78, 78);
}

.game_qa_notice_paging_box {
    display: flex;
    justify-content: center;
    width: 998px;
    height: 48px;
    border-top: 1px solid rgb(78, 78, 78);
    border-bottom: 1px solid rgb(78, 78, 78);
    text-align: center;
    margin-top: 40px;
    background-color: #333;
}

.game_quit_box {
    width: 998px;
    min-height: 300px;
    height: auto;
    border: 1px solid rgb(78, 78, 78);
    border-top: 2px solid rgb(78, 78, 78);
    background-color: #333;
    margin-top: 40px;
    margin-bottom: 40px;
}

.game_quit_title_box {
    position: relative;
    display: block;
    width: 100%;
    height: 87px;
    font-size: 20px;
    font-weight: bold;
    background-color: #333;
    border-bottom: 2px solid rgb(78, 78, 78);
}

.game_category_box_sub_title_2 {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    font-weight: normal;
    color: white !important;
    margin: 10px 64px 0 14px;
    line-height: 23px;
}

.MsoNormal span {
    color: white !important;
}


.policy {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PolicyTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 600;
}

.OperatingPolicyTitle {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
}

.PolicyTitle2 {
    font-size: 15px;
    font-weight: 600
}

.policy2 {
    width: 500px;
    margin-top: 15px;
    font-size: 14px;
    line-height: 3
}

ol {
    padding-left: 0;
    list-style-type: none !important;
    counter-reset: my-counter;
    margin-left: 30px !important
}

li {
    position: relative; /* Required for absolute positioning */
    display: block;
    clear: left; /* Ensure no text wraps around */
}

li::before {
    counter-increment: my-counter;
    content: counter(my-counter);
    width: 14px;
    height: 14px;
    line-height: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid white;
    margin-right: 0.625rem;
    vertical-align: middle;
    position: absolute;
    left: -1.875rem; /* Adjust as needed */
    top: 14px;
    align-item: center;
    font-size: 12px;
}

ol ol {
    padding-left: 0;
    list-style: auto !important;
    margin-left: 28.5px !important;
    counter-reset: item;
}

ol ol li {
    list-style: auto !important;
}

ol ol li::before {
    counter-increment: item;
    content: counter(item) ". ";
    border: none !important;
    font-size: 15px;
    top: 14.5px;
}


.custom_ol {
    padding-left: 0;
    list-style: auto !important;
    margin-left: 28.5px !important;
}

.custom_ol li {
    list-style: auto !important;
}

.custom_ol li::before {
    content: "-";
    border: none !important;
    font-size: 14px;
    top: 13.5px;
}


table {
    border-collapse: collapse;
    width: 100%;
    color: #FFFFFF;
}

td, th {
    border: 2px solid white;
    text-align: left;
    padding: 8px;
    text-align: center;
    font-size: 15px;
}

th {
    font-size: 17px;
    font-weight: 600;
    background-color: #333;
}

th:nth-child(1),
td:nth-child(1) {
    width: 125px;
}

.PrivacyTable1 td {
    border: 2px solid white;
    text-align: left;
    padding: 8px;
    /*    text-align: center; */
    font-size: 15px;
}

.PrivacyTable1 th:nth-child(1),
td:nth-child(1) {
    width: 125px;
    text-align: center;
}

.span_space {
    padding: 0.719rem;
}

.span_space2 {
    padding: 4.5px;
}

.PolicyContent {
    margin-top: 0 !important;
}


.info_area li::before {
    list-style: none;
    border: unset;
    content: unset;
}

.tabcontentGame li {
    clear: unset;
}

.tabcontentGame li::before {
    list-style: none;
    border: unset;
    content: unset;
    clear: unset;
}